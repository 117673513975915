<template>
<div class="home">
	<DenseContainer v-if="isLoading" class="text-center">
		<v-row>
			<v-col cols="12">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-col>
		</v-row>
	</DenseContainer>
	<DenseContainer v-else>
		<v-row>
			<v-col cols="12">
				<TableOfContent :blocks="blocks"></TableOfContent>
			</v-col>
		</v-row>
		<v-row>
			<BlockItem :block="block" v-for="block in blocks" :key="block.id"></BlockItem>
		</v-row>
		<v-row>
			<v-col cols="12" v-if="blocks.length == 0">
				<v-alert type="error">Es konnten keine Datenschutzinhalte geladen werden.</v-alert>
			</v-col>
		</v-row>
	</DenseContainer>
</div>
</template>

<script>
import BlockItem from '../components/items/BlockItem.vue';
import TableOfContent from '../components/items/TableOfContent.vue';
import firebase from 'firebase/app';

export default {
	data() {
		return {
			isLoading: true,
			blocks: []
		}
	},
	methods: {
		
	},
	mounted() {
		firebase.firestore().collection("blocks").where("visible", "==", true).where("apps", "array-contains-any", ["all", this.$route.params.hasOwnProperty("client_id") ? this.$route.params.client_id : "none"]).orderBy("order", "asc").get().then(docSnapshot => {
			docSnapshot.docs.forEach(doc => {
				this.$set(this.blocks, this.blocks.length, {
					id: doc.id,
					data: doc.data()
				});
			});
			this.isLoading = false;
		});
	},
	components: { BlockItem, TableOfContent},
};
</script>
