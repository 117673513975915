import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

Vue.config.productionTip = false

firebase.initializeApp({
	apiKey: "AIzaSyAynl0eKCrS4fowTOz6EwKZnm_-LOvltmM",
	authDomain: "datenschutz-d9058.firebaseapp.com",
	databaseURL: "https://datenschutz-d9058.firebaseio.com",
	projectId: "datenschutz-d9058",
	storageBucket: "datenschutz-d9058.appspot.com",
	messagingSenderId: "398662961118",
	appId: "1:398662961118:web:bf0f2ed34ab02285fbff4f"
});

if(location.hostname === "localhost") {
	firebase.firestore().useEmulator("localhost", 8081);
}

// Global components
import DenseContainer from './components/global/DenseContainer';
import AlertDialog from './components/global/AlertDialog';
Vue.component("DenseContainer", DenseContainer);
Vue.component("AlertDialog", AlertDialog);

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
