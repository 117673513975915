<template>
<v-card flat tile>
    <v-list class="py-0" dense tile>
        <v-list-item @click="clickItem(block)" v-for="(block, index) in blocks" :key="'toc_' + index" class="toc">
            <v-list-item-title class="primaryLight--text">{{block.data.title}}</v-list-item-title>
            <v-list-item-action>
                <v-icon color="primaryLight">mdi-chevron-down</v-icon>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</v-card>
</template>
<script>
export default {
    mounted() {
        
    },
    methods: {
        clickItem(block) {
            var top = document.getElementById(block.id).offsetTop;
            
            window.scrollTo(0, top - 25);
        }
    },
    props: {
        blocks: Array
    }
}
</script>
<style>
.toc {
    margin-bottom: 1px;
}
</style>