<template>
<v-app>
	<Navigation></Navigation>
	<ScreenSizeWatcher></ScreenSizeWatcher>
	<v-main>
		<router-view id="router-view"></router-view>
	</v-main>
</v-app>
</template>

<script>
import Navigation from './components/app/Navigation.vue';
import ScreenSizeWatcher from './components/app/ScreenSizeWatcher.vue';

export default {
	components: { Navigation, ScreenSizeWatcher },
	name: "App"
};
</script>
<style>
html {
	scroll-behavior: smooth;
}
#router-view {
	flex: 1 1 auto;
}
.app-container {
	width: 100%;
	max-width: 1000px!important;
	margin: 0 auto;
}
.v-dialog .theme--dark.v-card, .v-dialog .theme--dark.v-list, .v-dialog .theme--dark.v-stepper {
	background-color: #383838;
}
.theme--dark a {
	color: #fff!important;
}
/* Design-Anpassungen */
.v-application p {
	margin-bottom: 0;
}
</style>