<template>
<v-col cols="12">
    <v-card flat tile :id="block.id">
        <v-card-title>{{block.data.title}}</v-card-title>
        <v-card-subtitle v-if="block.data.subtitle">{{block.data.subtitle}}</v-card-subtitle>
        <v-card-text v-html="block.data.message"></v-card-text>
    </v-card>
</v-col>
</template>
<script>
export default {
    props: {
        block: Object
    }
}
</script>
<style lang="scss" scoped>
.anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}
</style>