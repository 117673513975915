<template>
<v-app-bar app fixed color="navigation" elevate-on-scroll>
    <DenseContainer class="py-0">
        <v-row no-gutters class="align-center">
            <v-col cols="12" class="line-height-1">
                <img class="v-app-bar-new-icon" :src="logo" height="28px">
            </v-col>
        </v-row>
    </DenseContainer>
</v-app-bar>
</template>
<script>
import firebase from 'firebase/app';

export default {
    data() {
        return {
            tab: 0,
            logo: null
        }
    },
    watch: {
        "$route.name"() {
            switch (this.$route.name) {
                case "Wishes":
                    this.tab = 0;
                    break;
                case "MyWishes":
                    this.tab = 1;
                    break;
                default:
                    this.tab = null;
                    break;
            }
        }
    },
    computed: {
        clientID() {
            return this.$route.params.hasOwnProperty("client_id") ? this.$route.params.client_id : "none"
        }
    },
    methods: {
        setDefaultLogo() {
            if(this.$vuetify.theme.dark) {
                this.logo = require("@/assets/Logos/logo_dark.svg")
            }else{
                this.logo = require("@/assets/Logos/logo_colored.svg")
            }
        },
        clickStore() {
            this.$router.push("/").catch(err => {});
        }
    },
    mounted() {
        if(this.clientID != "none") {
            let logo
            if(this.$vuetify.theme.dark) {
                logo = `logos/${this.clientID}_dark.svg`
            }else{
                logo = `logos/${this.clientID}_colored.svg`
            }
            firebase.storage().ref(logo).getDownloadURL().then(url => {
                this.logo = url
            }).catch(err => {
                this.setDefaultLogo()
            })
        }else{
            this.setDefaultLogo()
        }
    }
}
</script>
<style>
/* Fix, damit die Tabs unter dem Login NEW Dialog sind*/
.v-toolbar__content, .v-toolbar__extension {
    position: initial!important ;
}
/* Profiltext unter Profilbild ausblenden, da dort der Platz fehlt */
.v-toolbar__content .login-new-avatar span.login-new-avatar-below {
    display: none;
}
/* Fix, damit die Tabs bei sehr kleinen Handys funktionieren */
.v-slide-group__prev{
    display: none!important;
}
</style>
<style scoped>
#loginnew-dropdown {
    z-index: 9999999;
}
.line-height-1 {
    line-height: 1;
}
/* Login NEW closer fix */
header.v-app-bar {
    contain: initial;
    transform: none !important;
}
</style>